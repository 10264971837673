<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showpaye"
        max-width="1300px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span>Lettrage</span>
            <v-spacer></v-spacer>
            <v-text-field
              autocomplete="off"
              hide-details
              single-line
              solo-inverted
              clearable
              dense
              flat
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="pr-6"
            >
            </v-text-field>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <span>Montant Restant a lettrer : </span>

                  <span class="green--text">
                    {{
                      numberWithSpace(rest ? rest.toFixed(2) : "0.00") + " DA"
                    }}
                  </span>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-progress-linear
                    :value="(rest / facture.mont_ttc) * 100"
                    color="light-green darken-2"
                    height="25"
                    rounded
                  >
                    <template v-slot:default>
                      <strong
                        >{{
                          ((rest / facture.mont_ttc) * 100).toFixed(2)
                        }}%</strong
                      >
                    </template>
                  </v-progress-linear>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-data-table
                    v-model="doc_select"
                    :headers="mvmheaders"
                    :items="docs_list"
                    :item-key="'id'"
                    class="elevation-1"
                    :search="search"
                    ref="refName"
                    :key="klist"
                    :loading="data_load"
                    @current-items="getFiltered"
                  >
                    <template v-slot:item="{ item, isSelected, select, index }">
                      <tr
                        :class="isSelected ? 'green' : ''"
                        @click="toggle(isSelected, select, item)"
                      >
                        <td>
                          <v-layout>
                            {{ index + 1 }}
                          </v-layout>
                        </td>
                        <td class="d-flex align-center">
                          {{ item.nobon }}
                          <v-icon class="px-1" color="black" v-if="isSelected"
                            >mdi-check</v-icon
                          >
                        </td>
                        <td align="right">
                          {{ datefr(item.opr_date) }}
                        </td>
                        <td align="right">
                          {{ datefr(item.date_limite) }}
                        </td>
                        <td align="right">
                          {{
                            numberWithSpace(
                              item ? item.mont_ttc.toFixed(2) : "0.00"
                            )
                          }}
                        </td>
                        <td align="right">
                          {{
                            numberWithSpace(
                              item
                                ? (item.mont_ret_ttc
                                    ? item.mont_ret_ttc
                                    : 0
                                  ).toFixed(2)
                                : "0.00"
                            )
                          }}
                        </td>
                        <td align="right">
                          {{
                            numberWithSpace(
                              item
                                ? (item.ttc_paye ? item.ttc_paye : 0).toFixed(2)
                                : "0.00"
                            )
                          }}
                        </td>
                        <td align="right">
                          {{
                            numberWithSpace(
                              item.mont_fact_ttc
                                ? item.mont_fact_ttc.toFixed(2)
                                : "0.00"
                            )
                          }}
                        </td>
                        <td
                          align="right"
                          :class="isSelected ? '' : 'green--text'"
                        >
                          {{
                            numberWithSpace(
                              item.ttc_reste
                                ? item.ttc_reste.toFixed(2)
                                : "0.00"
                            )
                          }}
                        </td>
                        <td align="right">
                          <v-progress-linear
                            :value="
                              (item.mont_fact_ttc /
                                (item.mont_ttc -
                                  (item.mont_ret_ttc
                                    ? item.mont_ret_ttc
                                    : 0))) *
                              100
                            "
                            color="light-blue darken-2"
                            height="25"
                            rounded
                          >
                            <template v-slot:default>
                              <strong
                                >{{
                                  (
                                    (item.mont_fact_ttc /
                                      (item.mont_ttc -
                                        (item.mont_ret_ttc
                                          ? item.mont_ret_ttc
                                          : 0))) *
                                    100
                                  ).toFixed(2)
                                }}%</strong
                              >
                            </template>
                          </v-progress-linear>
                        </td>
                      </tr>
                    </template>
                    <template slot="body.append" v-if="docs_list.length > 0">
                      <tr>
                        <th class="title">Total</th>
                        <th class="title" colspan="3">
                          {{
                            search
                              ? filtereditems.length
                              : docs_list
                              ? docs_list.length
                              : 0
                          }}
                        </th>

                        <th class="title text-right">
                          {{ numberWithSpace(sumField("mont_ttc").toFixed(2)) }}
                        </th>
                        <th class="title text-right">
                          {{
                            numberWithSpace(sumField("mont_ret_ttc").toFixed(2))
                          }}
                        </th>
                        <th class="title text-right">
                          {{ numberWithSpace(sumField("ttc_paye").toFixed(2)) }}
                        </th>
                        <th class="title text-right">
                          {{
                            numberWithSpace(
                              sumField("mont_fact_ttc").toFixed(2)
                            )
                          }}
                        </th>
                        <th class="title green--text text-right">
                          {{
                            numberWithSpace(sumField("ttc_reste").toFixed(2))
                          }}
                        </th>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>

              <v-spacer></v-spacer>

              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :disabled="!changed"
                v-if="doc_select.length > 0"
              >
                Enregistrer
              </v-btn>
            </v-container>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import INSERT_FACT_DETAIL from "../graphql/Facture/INSERT_FACT_DETAIL.gql";
import ACTUAL_MVMS from "../graphql/Paiement/ACTUAL_MVMS.gql";
export default {
  components: {},
  name: "factlettform",
  props: {
    facture: Object,
    showForm: Boolean,
  },
  data: () => ({
    progress: false,
    valid: true,
    changed: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    search: null,
    doc_select: [],
    filtereditems: [],
    facts_list: [],
    mvms_list: [],
    klist: 200,
    data_load: false,

    mvmheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "BE No",
        value: "nobon",
        selected: true,
      },
      {
        text: "Date BE",
        value: "opr_date",
        slot: "date",
        align: "end",
        selected: true,
      },
      {
        text: "Due Date",
        value: "date_limite",
        align: "end",
        selected: true,
      },
      {
        text: "T.T.C",
        value: "mont_ttc",
        selected: true,
        align: "end",
      },
      {
        text: "Ret.",
        value: "mont_ret_ttc",
        selected: true,
        align: "end",
      },
      {
        text: "Paye.",
        value: "ttc_paye",
        selected: true,
        align: "end",
      },
      {
        text: "Facturé",
        value: "mont_fact_ttc",
        selected: true,
        align: "end",
      },
      {
        text: "Reste",
        value: "ttc_reste",
        selected: true,
        align: "end",
      },
      {
        text: "% Facturé",
        value: "ttc_reste",
        selected: true,
        align: "end",
        width: "100",
      },
    ],
  }),

  computed: {
    docs_list() {
      return this.mvms_list;
    },

    showpaye() {
      return this.showForm;
    },

    rest() {
      let r =
        this.facture.let_reste -
        this.doc_select.reduce((a, b) => a + (b["montant"] || 0), 0);

      return r >= 0 ? r : 0;
    },
  },
  watch: {},

  async created() {
    if (this.showForm) {
      let v = {
        where: {
          AND: [
            { column: "TIER_ID", value: this.facture.tier_id },
            { column: "STATUT_ID", operator: "GTE", value: 11 },
            { column: "MVM_TYPE", value: 3 },
            { column: "TTC_RESTE", operator: "GT", value: 0.01 },
          ],
        },
      };
      this.data_load = true;
      let r = await this.requette(ACTUAL_MVMS, v, "no-cache");
      this.data_load = false;
      if (r) {
        this.mvms_list = r.allmvm;

        this.klist++;
      }

      this.mvms_list.forEach((element) => {
        element.old_fact = element.mont_fact_ttc;
      });
    }
  },
  mounted() {},

  methods: {
    sumField(key) {
      let list = this.search ? this.filtereditems : this.docs_list;
      // sum data in give key (property)
      return list ? list.reduce((a, b) => a + (b[key] || 0), 0) : 0;
    },
    getFiltered() {
      if (this.search)
        this.filtereditems = this.$refs.refName.$children[0].filteredItems;
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    toggle(isSelected, select, item) {
      this.changed = true;
      if (isSelected) {
        select(!isSelected);
        item.mont_fact_ttc = item.old_fact;
        item.ttc_reste =
          item.mont_ttc -
          (item.mont_ret_ttc ? item.mont_ret_ttc : 0) -
          item.old_fact -
          item.ttc_paye;
        item.montant = 0;
      } else if (this.rest > 0) {
        item.montant = item.ttc_reste <= this.rest ? item.ttc_reste : this.rest;
        item.mont_fact_ttc = item.old_fact + item.montant;
        item.ttc_reste =
          item.mont_ttc -
          (item.mont_ret_ttc ? item.mont_ret_ttc : 0) -
          item.mont_fact_ttc -
          item.ttc_paye;
        select(!isSelected);
      }
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    async requette(query, v, f) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    close() {
      this.$emit("close");
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async save() {
      let list = [];
      this.docs_list
        .filter((elm) => elm.montant > 0)
        .forEach((element) => {
          let t = element.montant / element.mont_ttc;
          if (element.montant > 0)
            list.push({
              fact_id: this.facture.id,
              mvm_id: element.id,
              mont_ttc: element.montant,
              mont_tva: parseFloat((element.mont_tva * t).toFixed(2)),
              mont_ht: parseFloat((element.mont_ht * t).toFixed(2)),
            });
        });
      let v = {
        fact_id: this.facture.id,
        list: list,
      };
      let r = await this.maj(INSERT_FACT_DETAIL, v, true);
      if (r) {
        this.changed = false;
        this.close();
      }
    },
  },
};
</script>
